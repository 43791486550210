export let dateMixin= {
    methods:{
        // date String en DD-MM-YYYY HH:MM
        formatDate(dateString){
            let utc = dateString.charAt(21)
            // utc == heure 
            let date = new Date(dateString)
            let originalUTC = date.toISOString().split('T')[1].slice(0,5)
            let getHour = parseInt(originalUTC.split(':')[0])
            let displayedHour = getHour + parseInt(utc)
            date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
            return date
        },
        // date String en DD-MM-YYYY
        formatDate2(date){
            // récupérer le jour, mois et année
            let date1= date.substring(0,11)
            // diviser par -
            let date2 = date1.split('-')
            // reformater
            return date2[2]+'-'+date2[1]+'-'+date2[0]
        },
        // ajout des 10 minutes à la fin des dates de fin
        addTenMinutesToTime(date_parameter){
            let date_day = date_parameter.split(' ')[0]
            let date_time = date_parameter.split(' ')[1]
            const date = new Date(date_parameter);
            if(date_time == "23:55" || date_time =="23:50"){
                return date_parameter
            }
            if(date.getMinutes()%2 == 0){
                date.setMinutes(date.getMinutes() + 10);
            }else{
                date.setMinutes(date.getMinutes() + 5);
            }
            let time = date.toTimeString().slice(0,5)
            return date_day +' '+ time
        },
        
        reduceMinutes(date_parameter_with_timezone,duration){
            let hour_parameter = date_parameter_with_timezone.split('T')[1].substring(0,5)
            let date_day = date_parameter_with_timezone.split('T')[0]
            let date_parameter = `${date_day} ${hour_parameter}`
            const date = new Date(date_parameter);
            date.setMinutes(date.getMinutes() - (duration*60));
            let time = date.toTimeString().slice(0,5)
            return date_day +' '+ time
        },
        reduceMinutesSpace(date_parameter_with_timezone,duration){
            let hour_parameter = date_parameter_with_timezone.split(' ')[1].substring(0,5)
            let date_day = date_parameter_with_timezone.split(' ')[0]
            let date_parameter = `${date_day} ${hour_parameter}`
            const date = new Date(date_parameter);
            date.setMinutes(date.getMinutes() - (duration*60));
            let time = date.toTimeString().slice(0,5)
            console.log("après reduce", date_day +' '+ time+":00");
            return date_day +' '+ time+":00"
        },
        calculTimesWithHHMM(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            let stringHeure = ''
            if (heure) {
                stringHeure = heure+ ' h'
            }
            if (min) {
                stringHeure += min + 'mn'
            }
            return stringHeure
        }
    }
}